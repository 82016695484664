/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .user-menu-img {
    width: 2.5rem !important;
    padding-right: 0.8rem;
  }
  .log-input {
    height: 3.2rem;
    background-color: #f7f7f8;
    width: 100%;
    border: 0;
  }
  .log-textarea {
    height: 10rem;
    width: 21rem;
    border: 0;
    border-radius: 0.7rem;
    background-color: #f7f7f8;
    resize: none;
    padding-top: 0.8rem;
    padding-left: 0.9rem;
  }
  .count-text-1 {
    font-size: 16px;
    padding-top: 20px;
  }
  .count-text-2 {
    font-size: 12px;
    line-height: 14px;
  }
  .hover-white:hover {
    background-color: white !important;
  }
  .account-summ {
    height: 130px;
  }
  .wal-bal-text {
    font-size: 14px;
    padding-left: 13px;
    line-height: 0.8px;
    padding-top: 10px;
  }
  .wal-bal-amount {
    font-size: 16px;
    padding-left: 13px;
  }
  .services-basis {
    flex-basis: 45%;
  }
  .services-basis-user {
    flex-basis: 31%;
  }
  .service-icon {
    position: absolute;
    top: 0.5rem;
    right: 3rem;
    margin-right: 4rem;
  }
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
  .user-menu-img {
    width: 2.5rem !important;
    padding-right: 0.8rem;
  }
  .w-lg {
    width: 90% !important;
  }
  .log-input {
    height: 50px;
    background-color: #f7f7f8;
    width: 100%;
    border: 0;
  }
  .count-text-1 {
    font-size: 16px;
    padding-top: 20px;
  }
  .count-text-2 {
    font-size: 12px;
    line-height: 14px;
  }
  .services-basis {
    flex-basis: 45%;
  }
  .services-basis-user {
    flex-basis: 31%;
  }
  .log-textarea {
    height: 10rem;
    width: 21rem;
    border: 0;
    border-radius: 0.7rem;
    background-color: #f7f7f8;
    resize: none;
    padding-top: 0.8rem;
    padding-left: 0.9rem;
  }
  .service-icon {
    position: absolute;
    top: 0.5rem;
    right: 3rem;
    margin-right: 4rem;
  }
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
  .user-menu-img {
    width: 4rem !important;
    padding-right: 0.8rem;
  }
  .w-md {
    width: 90% !important;
  }
  .log-input {
    height: 50px;
    background-color: #f7f7f8;
    width: 100%;
    border: 0;
  }
  .count-text-1 {
    font-size: 16px;
    padding-top: 20px;
    text-align: center;
  }
  .count-text-2 {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }

  .img-fluid {
    max-width: 30% !important;
    height: auto !important;
    display: block;
    margin: auto;
  }
  .services-basis {
    flex-basis: 44%;
  }
  .services-basis-user {
    flex-basis: 30%;
  }
  .log-textarea {
    height: 10rem;
    width: 21rem;
    border: 0;
    border-radius: 0.7rem;
    background-color: #f7f7f8;
    resize: none;
    padding-top: 0.8rem;
    padding-left: 0.9rem;
  }
  .service-icon {
    position: absolute;
    top: 0.5rem;
    right: 3rem;
    margin-right: 4rem;
  }
  .wal-bal-text {
    font-size: 13px;
    padding-left: 14px;
    line-height: 0.8px;
    padding-top: 10px;
  }
  .wal-bal-amount {
    font-size: 12px;
    padding-left: 14px;
  }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .user-menu-img {
    width: 2.5rem !important;
    padding-right: 0.8rem;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .log-input {
    height: 50px;
    background-color: #f7f7f8;
    width: 100%;
    border: 0;
  }
  .count-text-1 {
    font-size: 16px;
    padding-top: 20px;
    text-align: center;
  }
  .count-text-2 {
    font-size: 12px;
    line-height: 13px;
    text-align: center;
  }

  .img-fluid {
    max-width: 30% !important;
    height: auto !important;
    display: block;
    margin: auto;
  }
  .services-basis {
    flex-basis: 45%;
  }
  .services-basis-user {
    flex-basis: 30%;
  }
  .log-textarea {
    height: 10rem;
    width: 21rem;
    border: 0;
    border-radius: 0.7rem;
    background-color: #f7f7f8;
    resize: none;
    padding-top: 0.8rem;
    padding-left: 0.9rem;
  }
  .service-icon {
    position: absolute;
    top: 0.5rem;
    right: 3rem;
    margin-right: 4rem;
  }
  .wal-bal-text {
    font-size: 13px;
    padding-left: 14px;
    line-height: 0.8px;
    padding-top: 10px;
  }
  .wal-bal-amount {
    font-size: 12px;
    padding-left: 14px;
  }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
  .user-menu-img {
    width: 2.5rem !important;
    padding-right: 0.8rem;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .log-input {
    height: 50px;
    background-color: #f7f7f8;
    width: 100%;
    border: 0;
  }
  .count-text-1 {
    font-size: 14px;
    padding-top: 20px;
    text-align: center;
  }
  .count-text-2 {
    font-size: 9px;
    line-height: 14px;
    text-align: center;
  }

  .img-fluid {
    max-width: 70% !important;
    height: auto !important;
    display: block;
    margin: auto;
  }
  .services-basis {
    flex-basis: 45%;
  }
  .services-basis-user {
    flex-basis: 31%;
  }
  .log-textarea {
    height: 10rem;
    width: 21rem;
    border: 0;
    border-radius: 0.7rem;
    background-color: #f7f7f8;
    resize: none;
    padding-top: 0.8rem;
    padding-left: 0.9rem;
  }
  .service-icon {
    position: absolute;
    top: 0.5rem;
    right: 3rem;
    margin-right: 4rem;
  }
  .wal-bal-text {
    font-size: 13px;
    padding-left: 14px;
    line-height: 0.8px;
    padding-top: 10px;
  }
  .wal-bal-amount {
    font-size: 12px;
    padding-left: 14px;
  }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  .user-menu-img {
    width: 2.5rem !important;
    padding-right: 0.8rem;
  }
  .w-sm {
    width: 90% !important;
  }
  .log-input {
    height: 50px;
    background-color: #f7f7f8;
    width: 100%;
    border: 0;
  }
  .count-text-1 {
    font-size: 16px;
    padding-top: 20px;

  }
  .count-text-2 {
    font-size: 12px;
    line-height: 14px;
  }

  .img-fluid {
    max-width: 70% !important;
    height: auto !important;
    display: block;
    margin: auto;
  }
  .services-basis {
    flex-basis: 95%;
  }
  .services-basis-user {
    flex-basis: 95%;
  }
  .log-textarea {
    height: 10rem;
    width: 21rem;
    border: 0;
    border-radius: 0.7rem;
    background-color: #f7f7f8;
    resize: none;
    padding-top: 0.8rem;
    padding-left: 0.9rem;
  }
  .offcanvas.offcanvas-bottom {
    height: 80vh !important;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .wal-bal-text {
    font-size: 13px;
    padding-left: 14px;
    line-height: 0.8px;
    padding-top: 10px;
  }
  .wal-bal-amount {
    font-size: 12px;
    padding-left: 14px;
  }
}
