* {
  margin: 0;
  padding: 0;
  /* font-family: Sk-Modernist; */
}
.body {
  font-size: 62.5%;
}
/* @font-face {
  font-family: Sk-Modernist;
  src: url(./assets/fonts/Sk-Modernist/Sk-Modernist-Regular.otf);
  src: url(./assets/fonts/Sk-Modernist/Sk-Modernist-Bold.otf);
  src: url(./assets/fonts/Sk-Modernist/Sk-Modernist-Mono.otf);
} */
a {
  color: black !important;
  text-decoration: none !important;
}
:root {
  --theme-color: #d41a5c;
  --gray-color: #818094;
  --gray-btn-color: #e4e4e4;
  --theme-color-2: #091b3d;
  --fc-button-bg-color: #fbe8ef;
  --fc-button-border-color: white;
  --fc-button-hover-bg-color: #fbe8ef;
  --fc-button-hover-border-color: white;
  --fc-button-active-bg-color: #fbe8ef;
  --fc-button-active-border-color: #fbe8ef;
  --fc-button-text-color: black;
  --fc-more-link-text-color: black;
  --fc-page-bg-color: white;
  --fc-bg-event-color: #fbe8ef;
  --toastify-color-success: var(--theme-color);
}
.dropdown-menu {
  --bs-dropdown-link-hover-bg: none !important;
  --bs-dropdown-link-active-bg: white !important;
  --bs-dropdown-link-hover-color: none !important;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: var(--toastify-color-success) !important;
}
.img-max {
  max-height: 100vh !important;
  object-fit: fill;
}
.w-10 {
  width: 10% !important;
}
.top-60 {
  top: 60% !important;
}
.h-20 {
  height: 20% !important;
}
.h-60 {
  height: 60% !important;
}
.w-60 {
  width: 60% !important;
}
.w-10 {
  width: 10% !important;
}
.w-7 {
  width: 7% !important;
}
.w-15 {
  width: 15% !important;
}
.w-65 {
  width: 65% !important;
}
.w-55 {
  width: 55% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-30 {
  width: 30% !important;
}
.w-20 {
  width: 20% !important;
}
.w-15 {
  width: 15% !important;
}
.w-90 {
  width: 90% !important;
}
.w-95 {
  width: 95% !important;
}
.w-70 {
  width: 70% !important;
}
.w-80 {
  width: 80% !important;
}
.w-82 {
  width: 82% !important;
}
.w-5 {
  width: 5% !important;
}
.bg-theme {
  background-color: var(--theme-color) !important;
}
.bg-theme-2 {
  background-color: var(--theme-color-2) !important;
}
.bg-theme-gray {
  background-color: var(--gray-btn-color) !important;
}
.h-70 {
  height: 70% !important;
}
.h-80 {
  height: 80% !important;
}
.h-90 {
  height: 90% !important;
}
.hover-white:hover {
  background-color: white;
}
.text-theme {
  color: var(--theme-color) !important;
}
.border-theme {
  border-width: 0.1rem !important;
  border-color: var(--theme-color) !important;
}
.text-gray {
  color: var(--gray-color);
}
.fs-7 {
  font-size: 0.8rem !important;
}
.fs-8 {
  font-size: 0.7rem !important;
}
.onboarding-sidebar {
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
.top-10 {
  top: 10% !important;
}
.top-5 {
  top: 5% !important;
}
.top-2 {
  top: 2% !important;
}
.top-1 {
  top: 1% !important;
}
.top-20 {
  top: 20% !important;
}
.top-30 {
  top: 30% !important;
}
.top-40 {
  top: 40% !important;
}
.top-60 {
  top: 60% !important;
}
.top-70 {
  top: 70% !important;
}
.top-80 {
  top: 80% !important;
}
.start-10 {
  left: 10% !important;
}
.start-15 {
  left: 15% !important;
}
.start-5 {
  left: 5% !important;
}
.end-10 {
  right: 10% !important;
}
.end-20 {
  right: 20% !important;
}
.start-25 {
  left: 25% !important;
}
.end-60 {
  right: 60% !important;
}
.start-20 {
  left: 20% !important;
}
.start-30 {
  left: 30% !important;
}
.start-35 {
  left: 35% !important;
}
.start-40 {
  left: 40% !important;
}
.start-45 {
  left: 45% !important;
}
.start-60 {
  left: 60% !important;
}
.bottom-10 {
  bottom: 10% !important;
}
.bottom-20 {
  bottom: 20% !important;
}
.bottom-30 {
  bottom: 30% !important;
}
.bottom-5 {
  bottom: 5% !important;
}
.bottom-1 {
  bottom: 1rem !important;
}
.nav-active {
  background: rgb(246, 152, 186);
  background: -moz-linear-gradient(
    90deg,
    rgba(246, 152, 186, 1) 6%,
    rgba(255, 255, 255, 1) 20%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(246, 152, 186, 1) 6%,
    rgba(255, 255, 255, 1) 20%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(246, 152, 186, 1) 6%,
    rgba(255, 255, 255, 1) 20%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f698ba",endColorstr="#ffffff",GradientType=1);
  transition: linear 2s;
}
.rounded-4 {
  border-radius: 9rem;
}
.rounded-4 {
  border-radius: 10rem;
}
.count-text-1 {
  font-size: 14rem;
  line-height: 0.2rem;
}
.count-text-2 {
  font-style: 14rem;
  line-height: 0.2rem;
}

.user-menu {
  right: 1rem !important;
}
.nav-bar {
  height: 4rem !important;
}

.or::before {
  content: "";
  width: 5rem;
  display: inline-block;
  border: 0.08rem solid black;
  margin-bottom: 0.2rem;
  margin-right: 1rem;
}
.or::after {
  content: "";
  width: 5rem;
  display: inline-block;
  border: 0.08rem solid black;
  margin-bottom: 0.2rem;
  margin-left: 1rem;
}
.or {
  text-align: center;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: none !important;
  border-width: 0;
}
.theader {
  background: #f8eff3;
  position: sticky;
  top: 0;
}

.lh-0 {
  line-height: 0;
}
.lh-04 {
  line-height: 0.4rem;
}
.lh-1 {
  line-height: 1rem;
}
.lh-2 {
  line-height: 2rem;
}
.lh-3 {
  line-height: 3rem;
}
.lh-4 {
  line-height: 4rem;
}
.lh-5 {
  line-height: 5rem;
}
.green-text {
  padding: 0.3rem;
  background-color: #ebf6ee;
  border-radius: 3rem;
  color: #34a853;
  font-size: 0.8rem;
  text-align: center;
}
.yellow-text {
  padding: 0.3rem;
  background-color: #fef8e7;
  border-radius: 3rem;
  color: #f4bd0b;
  font-size: 0.8rem;
  text-align: center;
}
.pink-text {
  padding: 0.3rem;
  background-color: #fff4f0;
  border-radius: 3rem;
  color: #ff8f6b;
  font-size: 0.8em;
  text-align: center;
}
.blue-text {
  padding: 0.3rem;
  background-color: #eff4ff;
  border-radius: 3rem;
  color: #5b93ff;
  font-size: 0.8rem;
  text-align: center;
}
.ms-6 {
  margin-left: 4rem;
}
.mt-6 {
  margin-top: 4rem !important;
}
.mt-7 {
  margin-top: 5rem !important;
}
.cursor-p {
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scrollbar::-webkit-scrollbar {
  display: none;
}

.flex-row-wrap {
  display: flex;
  flex-flow: row wrap;
}
.v-table-spacing {
  border-collapse: separate;
  border-spacing: 0 0.7em;
}
.avatar-box-user {
  width: 12rem;
  height: 11rem;
  margin: auto;
  background-color: #fff4f8;
  border-radius: 1rem;
  margin-top: 4rem;
}
.avatar-box-app {
  width: 12rem;
  height: 12rem;
  margin: auto;
  background-color: #f2f7ff;
  border-radius: 1rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.user-header {
  height: 20rem;
  background-color: #091b3d;
  border-radius: 1rem;
  margin-bottom: 1rem;
}
.card-size-user {
  width: 7rem;
  height: 7rem !important;
}
.input-icon {
  position: absolute;
  right: 1rem;
  top: 2.9rem;
  z-index: 100;
  cursor: pointer;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 10%;
  height: 3%;
  top: -18px;
}
.lds-ring div {
  box-sizing: border-box;
  display: inline-block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 1px;
  border: 1px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #d41a5c;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.transparent-input {
  background-color: transparent !important;
  color: white !important;
  border-width: 0.09rem !important;
  border-color: white !important;
  border-style: solid !;
}

container {
  height: 100vh;
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-items: center;
}
.box {
  max-width: 100%;
  width: 100%;
  padding: 0 15px;
}

.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.skeleton .line:last-child {
  margin-bottom: 0;
}
.h8 {
  height: 8px !important;
}
.h10 {
  height: 10px !important;
}
.h12 {
  height: 12px !important;
}
.h15 {
  height: 15px !important;
}
.h17 {
  height: 17px !important;
}
.h20 {
  height: 20px !important;
}
.h25 {
  height: 25px !important;
}
.w25 {
  width: 25% !important;
}
.w40 {
  width: 40% !important;
}
.w50 {
  width: 50% !important;
}
.w75 {
  width: 75% !important;
}
.m10 {
  margin-bottom: 10px !important;
}
.circle {
  border-radius: 50% !important;
  height: 80px !important;
  width: 80px;
}
@keyframes wave-lines {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes wave-squares {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.top-25 {
  top: 25% !important;
}

.dropup .dropdown-toggle::after {
  display: none !important;
  margin-left: 0;
  vertical-align: 0;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.bordered-btn {
  width: 95%;
  padding: 0.8rem;
  border: 0.1rem solid #d7d7d7;
  display: block;
  margin: auto;
  margin-top: 0.9rem;
  border-radius: 1rem;
  margin-bottom: 0.9rem;
}

.bordered-btn:hover {
  background-color: #fff5f9;
}
.img-fit-top {
  object-fit: cover;
  object-position: top;
}
.img-fit-left {
  object-fit: cover;
  object-position: left;
}
.border-black {
  border-color: black !important;
}
.dropdown-toggle::after {
  display: none !important;
}

.scrollbar-x {
  overflow-x: scroll;
}
.scrollbar-y {
  overflow-y: scroll;
}

.word-break {
  word-break: break-all !important;
}
.dashboard-select-input {
  background-color: #fbe8ef !important;
}
.settings-btn {
  border: 0.3rem solid #ced6e0 !important;
  color: #ced6e0 !important;
}

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
  margin-top: 20px;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  color: #84818a !important;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: var(--theme-color) !important;
  background: rgba(212, 26, 92, 0.1);
  padding: 10px;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
}

.transaction_search_icon {
  position: absolute;
  left: 0.3rem;
  z-index: 1000;
  top: 2.5rem;
}

.skeleton-loader-service-icon {
  width: 50px;
  height: 50px;
  border-radius: 30%;
  border: 6px solid #f5e8ec;
}

.skeleton-loader-service-button {
  height: 40px !important;
  width: 40% !important;
  border-radius: 15px;
}

.skeleton-text {
  width: 100%;
  height: 16px;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}
.skeleton-img {
  width: 20%;
  height: 40px;
  border-radius: 50%;
  margin-top: 16px;
}

.skeleton-text__body {
  width: 60%;
}

.skeleton-footer {
  width: 30%;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.appointment-approval {
  width: 95% !important;
  padding: 0.3rem;
  background-color: #f5faf6 !important;
  border-radius: 0.6rem;
  margin: auto;
}

.appointment-details {
  background-color: #fefcf3 !important;
  width: 95% !important;
  padding: 0.3rem;
  border-radius: 0.6rem;
  margin: auto;
  margin-top: 0.4rem;
}
